/* David Schraedel, July 2024 */

.dropdown_menu_container {
  /* display: block; */
  width: 250px;
  background-color: white;
  position: absolute;
  top: 15px;
  right: 15px;
  /* height: 100%; */
  height: fit-content;
  padding: 25px;
  border-top-left-radius: 8px;
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
  /* min-width: 300px; */
  box-shadow: 0px 0px 10px #e0e0e0;
  z-index: 3;

  padding-top: 30px;
  padding-left: 35px;
  padding-bottom: 40px;
  transition: visibility 0.3s ease-out, opacity 0.3s ease-out, transform 0.3s ease-out;
}
.dropdown_menu_container.active {
  opacity: 1;
  visibility: visible;
  transform: translate(0px, 0px);
}
.dropdown_menu_container.inactive {
  opacity: 0;
  visibility: hidden;
  transform: translate(20px, -20px);
}

.dropdown_menu_header {
  display: flex;
  justify-content: space-between;
  align-content: center;
  padding-inline: 20px;
  padding-block: 10px;

  .profile_identity {
    display: flex;
    justify-content: left;
    align-items: center;
  }

  .dropdown_menu_user_avatar {
    align-items: left;
    height: 55px;
    width: 55px;
    /* padding-top: 3px; */
    /* margin-right: 5px; */
    background-color: white;
    border-radius: 50%;
    transition: outline 0.05s ease-in-out;
    &:hover {
      outline: hsla(205, 43%, 26%, 1) 3px solid;
      outline-offset: -1px;
      /* box-shadow: 0px 0px 10px black; */
    }
  }
  .dropdown_menu_close_icon {
    align-self: center;
    padding: 8px;
    display: flex;

    color: #cfcfcf;
    border: 2px solid #e0e0e0;

    border-radius: 50%;
    transition: background-color 0.3s ease-out, border-color 0.3s ease-out, color 0.3s ease-out;

    &:hover {
      cursor: pointer;
      background-color: #26475f;
      border-color: #26475f;
      color: white;
    }
  }
}
a {
  text-decoration: none;
  color: black;
}
.profile_info {
  border-radius: 8px;
  background-color: #f7f7f7;
  box-shadow: inset 0px 0px 5px rgba(0, 0, 0, 0.1);
  margin-top: 2px;
  padding-block: 4px;
}
.profile_name {
  padding-inline: 10px;
  padding-top: 8px;

  overflow-wrap: anywhere;
}
.profile_username {
  padding-inline: 10px;
  padding-top: 4px;
  padding-bottom: 8px;
  overflow-wrap: anywhere;
  color: #26475f;
  font-weight: bold;
  font-size: small;
  &:hover {
    text-decoration: underline;
    cursor: pointer;
  }
}
.dropdown_menu_main {
  padding-top: 5px;
  margin-bottom: 15px;
}
.dropdown_menu_link {
  padding: 10px;
  margin-block: 5px;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease-out;
  &:hover {
    background-color: #e0e0e0;
  }
}
.dropdown_menu_link.active {
  background-color: #e0e0e0;
}

.dropdown_menu_divider {
  margin-block: 16px;
  width: 100%;
  height: 1px;
  background-color: #cfcfcf;
}

/* .dropdown_menu_container::before {
  content: "";
  position: absolute;
  top: -5px;
  right: 20px;
  height: 20px;
  width: 20px;
  background: white;
  transform: rotate(45deg);
} */
