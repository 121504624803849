/* General form styles */
.form-create-load {
  max-width: 900px;
  margin: 0 auto;
  padding: 20px;
  background-color: #f8f8f8;
  border-radius: 5px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  margin-top: -10px;

  /* Input field styles */
  input[type="text"],
  input[type="number"],
  input[type="date"] {
    width: 100%;
    padding: 10px;
    margin-bottom: 15px;
    border: 1px solid #ddd;
    border-radius: 3px;
    font-size: 16px;
  }

  select {
    width: 100%;
    padding: 10px;
    margin-bottom: 15px;
    border: 1px solid #ddd;
    border-radius: 3px;
    font-size: 16px;
  }

  textarea {
    width: 100%;
    padding: 10px;
    margin-bottom: 15px;
    border: 1px solid #ddd;
    border-radius: 3px;
    font-size: 16px;
    font-family: inherit;
  }

  input[type="checkbox"] {
    margin-top: 5px;
    margin-bottom: 15px;
    border: 1px solid #ddd;
    border-radius: 3px;
    font-size: 16px;
    font-family: inherit;
  }

  input[type="time"] {
    width: 100%;
    padding-inline: 10px;
    padding-block: 7.5px;
    margin-bottom: 15px;
    border: 1px solid #ddd;
    border-radius: 3px;
    font-size: 16px;
  }

  /* Radio button styles */
  .account-type {
    margin-bottom: 15px;
  }

  .account-type label {
    margin-right: 10px;
    font-size: 16px;
  }

  /* Submit button styles */
  button[type="submit"] {
    width: 100%;
    padding: 10px;
    background-color: #26475f;
    color: #fff;
    border: none;
    border-radius: 3px;
    font-size: 16px;
    cursor: pointer;
  }

  button[type="submit"]:hover {
    background-color: #333;
  }
}
