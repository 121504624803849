/* David Schraedel */

.search-results-container {
  max-width: 600px;
  margin: 20px auto;
  padding: 20px;
  border: 1px solid #ccc;
  border-radius: 10px;
  background-color: #f9f9f9;
}

.search-results-container h2 {
  text-align: center;
  margin: 20px;
}

/* Apply styles to the table */
.table-dashboard {
  border-collapse: separate;
  border-spacing: 10px;
  width: 100%;
}

/* Style for table header */
.table-dashboard th {
  border-radius: 5px; 
  background-color: lightgrey;
  padding: 10px;
  text-align: left;
}

/* Style for table body */
.table-dashboard td {
  background-color: #f9f9f9; /* Light gray background */
  padding: 10px;
  position: relative;
}

/* Style for table rows */
.pill-row {
  border-radius: 5px;
  transition: background-color 0.3s ease, box-shadow 0.3s ease, transform 0.3s ease, border 0.3s ease;
  transform: translateZ(0);
  will-change: transform;
}
.pill-row:hover {
  cursor: pointer;
  border-radius: 5px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  transform: translateY(-2px);
  backface-visibility: hidden;
  will-change: transform;
}
.pill-row:hover td {
  border-radius: 5px;
  background-color: #eaeaea;
}

.pagination-container {
  display: flex;
  justify-content: space-around;
  padding-top: 30px;
  padding-bottom: 20px;
}

.pagination-button {
  display: block;
  padding-inline: 30px;
  padding-block: 5px;
  background-color: hsl(205, 43%, 26%);
  color: #fff;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 16px;
}
.pagination-button:disabled {
  cursor: auto;
  background-color: hsl(205, 0%, 26%);
  /* color: #cbcbcb; */
  opacity: 50%;
}

.pagination-icon {
  padding-top: 3px;
}
