.chatScreen {
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100vh;
  background-color: #f4f6f8;
  font-family: 'Roboto', sans-serif;
  padding: 20px 0;
}

.groupNameContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 60%;
  padding: 10px 20px;
  background-color: #ffffff;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  margin-bottom: 20px;
  border-radius: 10px;
}

.backButton, .settingsButton, .closeButton {
  background: none;
  border: none;
  cursor: pointer;
}

.backButton:hover, .settingsButton:hover, .closeButton:hover {
  transform: scale(1.1);
}

.groupName {
  color: #26475F;
  font-size: 24px;
  font-weight: bold;
}

.messagesContainer {
  flex: 1;
  overflow-y: auto;
  display: flex;
  flex-direction: column-reverse;
  background-color: #ffffff;
  width: 60%;
  max-height: 70vh;
  border-radius: 10px;
  padding: 20px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.messageContainer {
  display: flex;
  flex-direction: column;
  margin: 10px 0;
}

.messageBox, .myMessageBox {
  padding: 10px 15px;
  border-radius: 10px;
  max-width: 80%;
  position: relative;
  display: flex;
  flex-direction: column;
}

.messageBox {
  background-color: #e0e0e0;
  color: #000000;
  align-self: flex-start;
}

.myMessageBox {
  background-color: #26475F;
  color: white;
  align-self: flex-end;
}

.username {
  font-size: 12px;
  color: gray;
  margin-bottom: 5px;
  padding-left: 5px;
}

.myUsername {
  font-size: 12px;
  color: gray;
  margin-bottom: 5px;
  padding-right: 5px;
  align-self: flex-end;
}

.messageContent {
  word-wrap: break-word;
  word-break: break-word;
  padding-left: 5px;
  padding-right: 5px;
}

.messageText {
  font-size: 16px;
}

.messageTextTimeStamp {
  font-size: 11px;
  color: gray;
  align-self: flex-begin;
  margin-top: 5px;
  padding-left: 5px;
}

.myMessageTextTimeStamp {
  font-size: 11px;
  color: gray;
  margin-top: 5px;
  padding-right: 5px;
  align-self: flex-end;
}

.inputContainer {
  display: flex;
  align-items: center;
  padding: 10px 20px;
  background-color: #ffffff;
  box-shadow: 0 -2px 4px rgba(0, 0, 0, 0.1);
  width: 60%;
  margin-top: 20px;
  border-radius: 10px;
}

.input {
  flex: 1;
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 10px;
  margin-right: 10px;
}

.sendButton {
  background-color: #26475F;
  color: white;
  padding: 10px 15px;
  border-radius: 10px;
  cursor: pointer;
  border: none;
}

.sendButton:hover {
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.2);
  transform: scale(1.02);
  border-color: #26475F;
}

.overlay {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 300px;
  height: 200px;
  background-color: white;
  border-radius: 20px;
  padding: 1em;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.overlayHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
}

.overlayContent {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.settingsTitle {
  font-size: 22px;
  font-weight: bold;
  text-align: center;
}

.settingsText {
  font-size: 18px;
  margin-bottom: 20px;
}

.leaveGroupButton {
  background-color: #DC3545;
  color: white;
  padding: 10px;
  border-radius: 10px;
  text-align: center;
  cursor: pointer;
  width: 100%;
  border: none;
  margin-bottom: 10px;
}

.leaveGroupButton:hover {
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.2);
  transform: scale(1.02);
  background-color: #DC3545;
}

.toggleTimestampButton {
  background-color: #26475F;
  color: white;
  padding: 10px;
  border-radius: 10px;
  text-align: center;
  cursor: pointer;
  width: 100%;
  border: none;
}

.toggleTimestampButton:hover {
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.2);
  transform: scale(1.02);
  background-color: #26475F;
}
