.user-profile {
  display: flex;
  align-items: center;
  padding: 20px;
  border: 1px solid #ccc;
  border-radius: 5px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.user-profile img {
  min-width: 100px;
  height: 100px;
  border-radius: 50%;
  margin-right: 20px;
}

.user-details {
  flex: 1;
  display: flex;
  flex-direction: column;
  position: relative;
}

.user-details p {
  margin: 0;
  margin-bottom: 10px;
}

.custom-rating-account {
  font-size: 24px;
}

.button-container {
  display: flex;
  justify-content: flex-end;
  margin-top: auto;
}

/* .edit-button {
  padding: 10px 15px;
  border: none;
  background-color: green;
  color: white;
  border-radius: 4px;
  cursor: pointer;
}

.edit-button:hover {
  background-color: #0056b3;
} */

.account-button {
  background-color: #26475f;
  color: white;
  border-radius: 8px;
  /* font-size: medium; */
}

.no-account-info {
  text-align: center;
  padding: 20px;
}

.my_loads_completed_button {
  text-align: center;
  font-size: 16px;
  font-weight: bold;
  color: #26475f;
  padding: 10px 30px;
  padding-top: 12px;
  cursor: pointer;
  transition: background-color 0.3s, color 0.3s, border 0.3s;
  border-radius: 6px;
  margin-block: 10px;
  background-color: #f4f6f8;
  box-shadow: inset 0 0px 8px rgba(0, 0, 0, 0.15);
  min-width: 230px;
}

.my_loads_completed_button:hover {
  background-color: lightgrey;
  border-color: lightgray;
}

.my_loads_completed_button:active {
  color: #fff;
  background-color: #26475f;
}

.my_loads_completed_button.selected {
  background-color: #26475f;
  color: #fff;
  box-shadow: none;
}

.my_loads_completed_button.selected:hover {
  background-color: #3a5a73;
}
