.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
}

.modal-content {
  background: white;
  padding: 20px;
  border-radius: 8px;
  width: 300px;
  position: relative;
  text-align: center;
}

.modal-close {
  position: absolute;
  top: 10px;
  right: 10px;
  background: transparent;
  border: none;
  font-size: 20px;
  cursor: pointer;
}

.modal-options {
  margin: 20px 0;
}

.modal-option {
  display: block;
  margin: 10px 0;
  padding: 10px;
  width: 100%;
  cursor: pointer;
  background-color: #f0f0f0;
  border: none;
  border-radius: 4px;
}

.modal-option:hover,
.modal-option.selected {
  background-color: gray;
  color: white;
}

.file-upload-section {
  margin-top: 20px;
}

.upload-button {
  margin: 12px;
  padding: 10px 15px;
  border: none;
  background-color: #26475f;
  color: white;
  border-radius: 4px;
  cursor: pointer;
}

.upload-button:hover {
  background-color: slategray;
}

.upload-button:disabled {
  background-color: gray;
  cursor: not-allowed;
}
