.sign_up_logo_center {
  display: flex;
  justify-content: center;
  align-items: center;
  /* border: 4px solid #ff0000; */
  /* width: fit-content; */
}

@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@400;500&display=swap");

.container-signup {
  font-family: "Poppins", sans-serif;
  border-radius: 8px;
  padding-bottom: 40px;
  max-width: 600px;
  margin: 0 auto;
  position: relative;

  margin-top: 100px;
  /* padding-top: 40px; */

  /* min-height: 750px; */
  padding-bottom: 250px;
}
.signup-form {
  input[type="text"] {
    width: 100%;
    font-size: 0.9rem;
    padding: 12px 20px;
    margin: 8px 0;
    display: inline-block;
    border: 1px solid #ccc;
    border-radius: 4px;
    box-sizing: border-box;
  }
  margin-top: 30px;
  /* margin-bottom: 10px; */
  display: block;
  /* height: fit-content; */
}

.form-input-signup {
  width: 100%;
  font-size: 0.9rem;
  padding: 12px 20px;
  margin: 8px 0;
  display: inline-block;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-sizing: border-box;
}

.btn-signup {
  /* background-color: #4CAF50; */
  /* color: white;
  padding: 14px 20px;
  margin: 8px 0;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  width: 100%; */
  background-color: #26475f;
  font-size: medium;
}
.btn-signup:hover {
  background-color: #333;
}

.user_type_signup {
  /* text-align: center; */
  display: flex;
  gap: 15px;
  justify-content: center;
  margin-block: 30px;
}

/* .centered-signup {
  text-align: center;
} */

/*  */
/*  */
