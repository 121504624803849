.reviews-header {
  background-color: #26475f;
  color: #fff;
  text-align: center;
  padding: 10px;
  width: 100%;
  margin: 0 auto;
}

.reviews-container {
  font-family: "Poppins", sans-serif;
  border-radius: 10px;
  max-width: 600px;
  margin: 2rem auto;
  margin-top: 1rem;
  margin-bottom: 200px;
  padding-bottom: 10px;
  max-height: 70vh;
  overflow-y: auto;
  overflow-x: hidden;
  padding-inline: 20px;
}

.received-review-container {
  padding: 15px;
  background-color: #fff;
  border: 1px solid #ccc;
  border-radius: 10px;
  margin: 10px 0;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.05);
}

.review-submitter {
  font-size: 16px;
  font-weight: bold;
  margin-bottom: 8px;
}

.custom-rating-list-reviews {
  font-size: 24px;
  color: #ffc107; /* Star color */
  margin-bottom: 8px;
}

.received-review-comment,
.received-review-no-comment {
  padding-left: 10px;
  margin-top: 5px;
  word-wrap: break-word; /* or use word-break: break-word; */
}

.received-review-no-comment {
  color: #888; /* Placeholder text color */
  font-weight: bold;
}
