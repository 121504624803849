body {
  font-family: Arial, sans-serif;
  background-color: #f4f4f4;
  margin: 0;
  padding: 0;
}

.images {
  text-align: center;
  padding-left: 30px;
  padding-right: 30px;
}

.map_image {
  width: 100%;
  height: auto;
  border-radius: 10px;
}

.column {
  float: left;
  width: 50%;
  padding: 20px;
  border: 1px solid #ccc;
  border-radius: 10px;
  margin-left: 20px;
  box-sizing: border-box;
  background-color: #fff;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.row:after {
  content: "";
  display: table;
  clear: both;
}

.about_section {
  background-color: white;
  display: flex;
  align-items: center;
}

.about_section > div {
  flex: 1;
  padding-right: 50px;
}

.about_words {
  text-align: center;
  color: #26475f;
  font-size: 30px;
}

.about_text {
  padding-left: 50px;
  font-size: 20px;
  color: #26475f;
}

.button {
  border: none;
  color: white;
  padding: 16px 32px;
  text-align: center;
  text-decoration: none;
  display: block;
  font-size: 16px;
  margin: auto;
  transition-duration: 0.4s;
  cursor: pointer;
  border-radius: 5px;
}

.button1 {
  background-color: #26475f;
  color: white;
}

.button1:hover {
  background-color: #333;
  color: white;
}

.button2 {
  background-color: #26475f;
  color: white;
}

.button2:hover {
  background-color: white;
  color: #26475f;
}

.box {
  width: auto;
  height: auto;
  background-color: #939598;
  color: white;
  padding: 50px;
  font-size: 25px;
  text-align: center;
  margin: 20px;
  border-radius: 10px;
}

.questions {
  background-color: #7d93aa;
  color: #26475f;
  padding: 50px;
  font-size: 25px;
  text-align: center;
  margin: 20px;
  border-radius: 10px;
}

h1 {
  font-size: 80px;
  margin: 0;
}

.questions_text {
  font-size: 20px;
  color: white;
  font-style: italic;
  text-align: center;
  padding: 0 50px;
}

.app {
  background-color: #939598;
  padding: 20px;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 20px;
  border-radius: 10px;
}

.app img {
  border: 15px solid white;
  margin: 20px;
  width: 200px;
  height: auto;
  display: inline-block;
}

.app_details {
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: left;
}

.title {
  font-size: 50px;
  color: white;
}

.mobile_app {
  display: flex;
  align-items: center;
  background-color: white;
  padding: 50px;
  margin: 20px;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.mobile_image {
  width: 40%;
  height: auto;
  padding-left: 50px;
}

.mobile_details {
  flex: 1;
  padding: 20px;
  text-align: center;
}

.mobile_app_header {
  font-size: 35px;
  color: #26475f;
  margin-bottom: 10px;
}

.key_features_header {
  font-size: 30px;
  color: #26475f;
  margin-bottom: 20px;
}

.key_features_columns {
  display: flex;
  justify-content: space-between;
}

.key_features_list {
  list-style-type: none;
  padding: 0;
  font-size: 22px;
}

.key_features_list li {
  margin-bottom: 10px;
  display: flex;
  align-items: center;
}

.arrow {
  margin-right: 10px;
  color: #26475f;
  font-size: 24px;
}

.qr_code {
  width: 200px;
  height: auto;
}
