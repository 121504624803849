/* David Schraedel - Jul 2024 */

.load_details_page {
  margin-bottom: 50px;
}

.back_button_text {
  font-size: 20px;
  /* vertical-align: middle; */
  /* border: 2px red solid; */
  padding-top: 4px;
}
.load_details_header {
  display: flex;
  justify-content: space-around;
  align-content: center;
  margin-inline: 20px;

  h1 {
    font-size: 24px;
    margin-block: auto;
  }
  h6 {
    margin-block: auto;
  }
}
.load_details_title {
  margin-block: auto;
  /* color: #26475f; */
}
.load_details_posted_date {
  align-content: center;
  font-size: 0.7em;
}
/* .review_container {
  align-content: center;
  display: flex;
} */

.load_details_flex_container {
  display: flex;
  justify-content: center;
  align-content: start;
  /* margin: 10px; */
  padding: 10px;
  gap: 10px;

  /*  */
  /* border: 2px red solid; */
}
@media (max-width: 900px) {
  .load_details_flex_container {
    flex-direction: column;
  }
  .load_details_header {
    display: block;
  }
  .load_details_header_left {
    margin-bottom: 10px;
  }
}

.load_details_header_left {
  display: flex;
  gap: 40px;
  justify-content: start;
}

.load_details_container_col_left {
  display: flex;
  flex-direction: column;
  justify-content: start;
  flex-grow: 2;
  flex-basis: 0;
  gap: 10px;
}

.load_details_container_col_right {
  display: flex;
  flex-direction: column;
  justify-content: start;
  flex-grow: 1;
  flex-basis: 0;
  gap: 10px;
}

.load_details_load_item {
  /* display: flex; */
  padding: 10px;
  box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
}
.load_details_requirements_item {
  /* display: flex; */
  padding: 10px;
  box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
}
.load_details_interaction_item {
  /* display: flex; */
  padding: 10px;
  box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
}
.load_details_owner_item {
  /* display: flex; */
  padding: 10px;
  box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
}

.load_details_pane_header {
  color: #26475f;
  border-radius: 8px;
  padding-inline: 10px;
  font-size: 40px;
  /* padding-block: 6px; */
  /* box-shadow: inset 0px 0px 20px rgba(0, 0, 0, 0.1); */
}

.load_details_section_header {
  display: flex;
  justify-content: space-between;
  color: #26475f;
  border-radius: 8px;
  padding-inline: 10px;
  /* padding-block: 0px; */
  margin-top: 8px;
  h2 {
    flex: 4;
    margin-block: 10px;
  }
}
.load_details_accordian_button {
  /* width: 100%; */
  flex: 1;
  /* margin-block: 15px; */
  margin-block: auto;
  padding-block: 5px;
  background-color: #26475f;
  color: #fff;
  font-size: 20px;
  border-radius: 5px;
  border: none;
  transition: transform 0.2s ease-in-out, background-color 0.2s ease-in-out;
  &:hover {
    background-color: #333;
    transform: scale(102%);
  }
  &:active {
    transform: translate(0px, 1px);
  }
}

.load_details_table {
  border-collapse: separate;
  text-align: left;
  border-spacing: 5px;
  width: 100%;
  border-radius: 8px;
  background-color: hsl(205, 5%, 94.5%);

  th {
    background-color: hsl(0, 0%, 95.5%);
    padding: 10px;
    padding-right: 5px;
    border-radius: 2px;
  }
  td {
    background-color: #f9f9f9;
    padding: 10px;
    border-radius: 2px;
    &:hover {
      box-shadow: 0px 0px 5px hsla(205, 43%, 26%, 0.2);
    }
  }
}

.load_details_table_container {
  max-height: 400px; /* Set your desired max height */
  overflow-y: auto; /* Enable vertical scrolling */
  width: 100%;
}

/* PRIOR STYLES */
.load-details-back-button-container {
  position: block;
  margin-left: 20px;
  margin-block: 15px;
}

body {
  font-family: Arial, sans-serif;
  background-color: #f4f4f4;
}

/* Back Button */
.back-button {
  font-size: 28px;
  background-color: transparent;
  border: none;
  cursor: pointer;
  transition: transform 0.3s ease;
  /* margin: 20px; */
  display: flex;
  gap: 7px;
}

.status-refresh-container {
  display: flex;
  align-items: center; /* Center vertically */
  gap: 2px; /* Space between status and button */
  /* h3 {
    font-weight: normal;
  } */
  padding-bottom: 8px;
}
.status-value {
  margin-left: 6px;
  font-size: 1.3em;
  padding-block: 5px;
  padding-inline: 10px;
  border-radius: 8px;
  background-color: #cddbe4;
  color: #26475f;
  &:not([title="new"]) {
    color: #5a5134;
    background-color: #f3d986;
  }
  &[title="complete"] {
    color: #416341;
    background-color: #c8eac8;
  }
  &[title="pending"] {
    color: #b02a38;
    background-color: #f7d4d6;
  }
}
.refresh-button {
  font-size: 20px;
  background-color: transparent;
  border: none;
  cursor: pointer;
  transition: transform 0.3s ease;
}
.refresh-button:hover {
  transform: scale(1.1);
}
.refresh-button:active {
  transform: scale(0.9);
}
.refresh-button:focus {
  outline: none;
}

.review-button {
  color: white;
  background-color: #26475f;
  padding: 14px 20px;
  /* margin-top: 8px; */
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 0.9em;
  margin: auto;
  text-wrap: nowrap;
}
.review-button:hover {
  background-color: #333;
}
/* .review-edit-link {
  color: rgb(0, 123, 255);
  cursor: pointer;
  display: block;
  padding-top: 2px;
  padding-right: 1px;
  width: fit-content;
}
.review-edit-link:hover {
  text-decoration: underline;
} */

.user-profile-load-details {
  display: flex;
  align-items: end;
  justify-content: start;
  padding-inline: 20px;
  padding-bottom: 10px;
}
.user-profile-load-details img {
  width: 100px;
  /* min-width: 100px; */
  height: 100px;
  border-radius: 50%;
  margin-right: 20px;
}

.btn-load-details {
  padding: 14px 20px;
  /* margin: 8px 0; */
  border: none;
  border-radius: 4px;
  cursor: pointer;
}
.btn-load-details-copy {
  background-color: hsl(46, 67%, 39%);
  color: white;
  padding-block: 14px;
  width: 85px;
  /* margin: 10px auto; */
  margin: auto;
  border: none;
  border-radius: 4px;
  font-size: 0.9em;
  cursor: pointer;
  &:hover {
    background-color: #333;
  }
}
.btn-load-details-edit {
  background-color: green;
  color: white;
  padding-block: 14px;
  width: 85px;
  /* margin: 10px auto; */
  margin: auto;
  border: none;
  border-radius: 4px;
  font-size: 0.9em;
  cursor: pointer;
}
.btn-load-details-edit:hover {
  background-color: #333;
}
.btn-load-details-delete {
  background-color: #dc3545;
  color: white;
  padding-block: 14px;
  width: 85px;
  /* margin: 10px auto; */
  margin: auto;
  border: none;
  border-radius: 4px;
  font-size: 0.9em;
  cursor: pointer;
}
.btn-load-details-delete:hover {
  background-color: #333;
}
.button_container {
  display: flex;
  /* justify-content: center; */
  gap: 10px;
}

/* Driver Request Buttons */
.btn-driver-accept,
.btn-driver-reject {
  padding: 10px 20px;
  margin: 5px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  color: #fff;
}
.btn-driver-accept {
  background-color: #26475f;
}
.btn-driver-reject {
  background-color: #dc3545;
}
.btn-driver-accept:hover,
.btn-driver-reject:hover {
  background-color: #333;
}
.button-container-requests {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  max-width: 200px;
  margin: 0 auto;
}
.btn-load-view_info {
  background-color: #ccc;
  padding: 10px 20px;
  margin: 5px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  white-space: nowrap;
}
.btn-load-view_info:hover {
  background-color: #333;
  color: white;
}

/* Rate Confirmation and Bill of Lading */
.rate-confirmation-container,
.pickup-confirmation-container,
.delivery-confirmation-container,
.driver-request-container {
  margin-top: 20px;
  text-align: center;
  margin-bottom: 80px;
}

.rate-confirmation-section,
.driver-pickup-confirmation-section,
.shipper-pickup-confirmation-section,
.driver-delivery-confirmation-section,
.shipper-delivery-confirmation-section,
.driver-request-section,
.review-section {
  border: 1px solid #ccc;
  border-radius: 5px;
  padding: 20px;
  max-width: 500px;
  margin: 0 auto;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  background-color: #f9f9f9;
}

.rate-confirmation-section h2,
.rate-confirmation-section h3,
.driver-pickup-confirmation-title,
.shipper-pickup-confirmation-title,
.driver-delivery-confirmation-title,
.shipper-delivery-confirmation-title {
  color: #26475f;
}

.file-upload-section {
  margin-bottom: 20px;
}
.selected-file {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 10px;
}
.selected-file p {
  flex: 1;
  margin: 0;
  padding-right: 10px;
  text-align: left;
}
.additional-files-li {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 5px;
}

.btn-delete {
  background: none;
  border: none;
  color: red;
  font-size: 1.5em;
  cursor: pointer;
  padding: 0;
  margin-left: 10px;
  transition: transform 0.3s ease;
}
.btn-delete:hover {
  color: darkred;
  transform: scale(1.2);
}

.btn-upload,
.btn-request {
  width: 50%;
  padding: 10px 16px;
  background-color: #26475f;
  color: #fff;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 1em;
}

.btn-upload:disabled {
  background-color: #ccc;
  cursor: not-allowed;
}

.btn-upload:hover,
.btn-request:hover {
  background-color: #333;
}

.document-link {
  display: inline-block;
  color: #007bff;
  text-decoration: none;
}
.document-link:hover {
  text-decoration: underline;
}

.driver-btn-confirm-pickup {
  margin-top: 10px;
  padding: 10px 20px;
  background-color: #26475f;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}
.driver-btn-confirm-pickup:hover {
  background-color: #333;
}

.shipper-pickup-confirmation-message,
.shipper-delivery-confirmation-message {
  margin-top: 15px;
}

/* Modal Styles */
.modal-overlay {
  background-color: rgba(0, 0, 0, 0.75);
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
}

.driver-modal-content {
  background-color: #fff;
  border-radius: 10px;
  padding: 20px;
  max-width: 800px;
  width: 90%;
  margin: 0 auto;
  text-align: left;
  position: relative;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.3);
}

.modal-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid #ccc;
  padding-bottom: 10px;
}
.modal-header h2 {
  margin: 0;
  font-size: 24px;
  color: #26475f;
}

.btn-close {
  background: none;
  border: none;
  font-size: 24px;
  cursor: pointer;
}

.modal-body {
  padding-top: 20px;
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.modal-body img {
  width: 120px;
  height: 120px;
  border-radius: 50%;
  object-fit: cover;
}

.modal-body .driver-info,
.modal-body .driver-details {
  flex: 1;
}

.modal-body .driver-info p,
.modal-body .driver-details p {
  margin: 5px 0;
}

.modal-body .driver-details p {
  font-size: 14px;
}

.modal-body .custom-rating-load-details {
  font-size: 24px;
  margin: 10px 0;
}

/* General Button Styles */
button {
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.btn-close {
  background-color: #f5f5f5;
  color: black;
  transition: background-color 0.3s ease;
}

.btn-close:hover {
  background-color: #dc3545;
  color: white;
}

/* Link Styles */
.modal-body .document-link {
  display: inline-block;
  color: #007bff;
  text-decoration: none;
}

.modal-body .document-link:hover {
  text-decoration: underline;
}

/* Request Load styles */
.driver-request-container {
  margin-top: 20px;
  text-align: center;
  margin-bottom: 80px;
}
.driver-request-section {
  border: 1px solid #ccc;
  border-radius: 5px;
  padding: 20px;
  max-width: 250px;
  margin: 0 auto;
  background-color: #f9f9f9;
}

.btn-request {
  width: 50%;
  padding: 10px 16px;
  background-color: #26475f;
  color: #fff;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}
.btn-request:hover {
  background-color: #333;
}

.custom-rating-load-details {
  font-size: 30px; /* Adjust the font size as needed */
  margin-bottom: 5px;
  text-wrap: nowrap;
}

.btn-submit {
  background-color: #26475f;
  color: white;
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 1em;
  margin-right: 10px;
}
.btn-submit:hover {
  background-color: #333;
}

.btn-cancel {
  background-color: #dc3545;
  color: white;
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 1em;
  margin-left: 10px;
}
.btn-cancel:hover {
  background-color: #333;
}

.additional-files-list {
  list-style-type: none;
  padding-left: 0;
  margin: 0;
}
.additional-files-li {
  margin-bottom: 5px;
}
