/* DriverRequest.css */
.driver-request-modal {
  padding: 20px;
  text-align: left;
  max-width: 800px;
  margin: 0 auto;
}

/* .driver-details {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: left;
  border: 1px solid #ccc;
  border-radius: 10px;
  padding: 20px;
  background-color: #f9f9f9;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  width: 100%;
} */

.profile-header {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 20px;
}

.user-profile-load-details img {
  width: 150px;
  height: 150px;
  border-radius: 50%;
  margin-bottom: 20px;
}

.custom-rating-load-details {
  font-size: 2em; /* Adjust the font size as needed */
}

.driver-info-columns {
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin-top: 20px;
}

.column {
  width: 48%;
}

.column p {
  margin: 5px 0;
  font-size: 1em;
}

.column strong {
  color: #333;
}

.column a {
  color: #007bff;
  text-decoration: none;
}

.column a:hover {
  text-decoration: underline;
}
