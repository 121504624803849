.photo_center_login {
  display: flex;
  justify-content: center;
  align-items: center;
  /* border: 4px solid #ff0000; */
  /* width: fit-content; */
}

@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@400;500&display=swap");

.container_login {
  font-family: "Poppins", sans-serif;
  border-radius: 8px;
  padding-bottom: 40px;
  max-width: 600px;
  margin: 0 auto;
  position: relative;

  margin-top: 100px;
  /* padding-top: 40px; */

  /* min-height: 750px; */
  padding-bottom: 250px;
}

.login-form {
  margin-top: 30px;
  /* margin-bottom: 10px; */
  display: block;
  /* height: fit-content; */
  p {
    margin-block: 30px;

    text-align: center;
  }
}

.form-input {
  width: 100%;
  font-size: 0.9rem;
  padding: 12px 20px;
  margin: 8px 0;
  display: inline-block;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-sizing: border-box;
}

.btn {
  /* background-color: #4caf50; */
  color: white;
  padding: 14px 20px;
  margin: 8px 0;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  width: 100%;
}

.btn-login {
  font-size: medium;
  background-color: #26475f;
}

.btn-login:hover {
  background-color: #333;
}

.sign-up-link {
  padding-top: 10px;
  color: hsl(240, 50%, 50%); /* Change color as desired */
  text-decoration: underline;
  cursor: pointer; /* Show pointer cursor on hover */
}

.sign-up-link:hover {
  color: hsl(240, 54%, 35%); /* Change color as desired */
}
