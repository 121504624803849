.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  /* min-height: 100vh; */
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

/* Navigation Bar Styles */

header nav {
  background-color: white;
  color: #fff;
  display: flex;
  justify-content: space-between;
  padding: 1rem;
}

header nav ul {
  display: flex;
  list-style-type: none;
  margin-top: -30;
  padding: 0;
  float: right;
  z-index: 100;
}

header nav ul li {
  margin: 0 1rem;
}

header nav ul li a {
  color: #26475f;
  text-decoration: none;
  transition: color 0.3s ease;
  font-size: 22px;
  font-style: bold;
}

header nav ul li a:hover {
  color: #f2a900;
}

/* Footer Styles */
footer {
  background-color: #333;
  color: #fff;
  font-size: 0.8rem;
  padding: 1rem;
  text-align: center;
  position: block;
}

.header_image {
  align-items: left;
  height: 50px;
  margin-top: 3px;
  background-color: white;
}

.privacy-link {
  color: white;
}

.header_user_avatar {
  align-items: left;
  height: 50px;
  width: 50px;
  margin-top: 3px;
  margin-right: 5px;
  background-color: white;
  border-radius: 50%;
  transition: box-shadow 0.25s ease-out, transform 0.1s ease-in;
  &:hover {
    cursor: pointer;
    box-shadow: 0px 0px 8px hsla(0, 0%, 20%, 0.4);
    transform: translate(0px, 1px);
  }
}
