/* David Schraedel */

.leave-review-container {
  font-family: "Poppins", sans-serif;
  border-radius: 8px;
  max-width: 400px;
  margin: 2rem auto;
  /* margin-top: 4rem; */
  flex: 100;
  padding-bottom: 100px;
}

.photo-review-recipient {
  padding-bottom: 16px;
  padding-top: 30px;

  border: 2px solid #ccc;
  border-radius: 5px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.photo-review-recipient h1 {
  text-align: center;
}

.photo-review-recipient img {
  display: flex;
  width: 200px;
  height: 200px;
  object-fit: cover;
  align-items: center;
  border-radius: 50%;
  margin: 0 auto;
}

.custom-rating-review {
  font-size: 30px;
  margin-top: 10px;
}

.review-required-marker {
  color: #dc3545;
  font-size: 18px;
  height: 30px;
  padding-left: 4px;
}

.review-rating-container {
  align-items: center;
  display: flex;
  padding-top: 16px;
}

.review-comment {
  max-width: 100%;
  margin-top: 20px;
}

.btn-submit-review {
  color: white;
  background-color: #26475f;
  padding: 14px 20px;
  margin-top: 15px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: medium;
}

.btn-submit-review:hover {
  background-color: #333;
}

.review-input-error-message {
  color: #dc3545;
  font-weight: bold;
  text-align: center;
  padding-top: 1rem;
}

.button-container {
  display: flex;
  justify-content: left;
}