.back-button-container {
  position: block;
  margin-left: 20px;
  margin-top: 20px;
}

.back-button {
  position: inherit;
  font-size: 28px;
  background-color: transparent;
  border: none;
  cursor: pointer;
}

.h1-custom {
  text-align: center;
  /* margin-top: 2rem; */
}

.form-edit-load {
  /* width: 100%; */
  max-width: 700px;
  margin: 0 auto;
  padding: 20px;
  background-color: #f8f8f8;
  border-radius: 5px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);

  /* Input field styles */
  input[type="text"],
  input[type="number"],
  input[type="date"] {
    display: flex;
    width: 95%;
    padding: 10px;
    margin-bottom: 15px;
    border: 1px solid #ddd;
    border-radius: 3px;
    font-size: 16px;
  }

  select {
    display: flex;
    width: 95%;
    padding: 10px;
    margin-bottom: 15px;
    border: 1px solid #ddd;
    border-radius: 3px;
    font-size: 16px;
  }

  textarea {
    display: flex;
    width: 95%;
    max-width: 95%;
    padding: 10px;
    margin-bottom: 15px;
    border: 1px solid #ddd;
    border-radius: 3px;
    font-size: 16px;
    font-family: inherit;
  }

  input[type="checkbox"] {
    margin-top: 5px;
    border-radius: 3px;
    font-size: 16px;
    font-family: inherit;
    padding: 10px;
    margin-bottom: 15px;
    border: 1px solid #ddd;
  }

  input[type="time"] {
    display: flex;
    width: 95%;
    padding-inline: 10px;
    padding-block: 7.5px;
    margin-bottom: 15px;
    border: 1px solid #ddd;
    border-radius: 3px;
    font-size: 16px;
  }

  /* Radio button styles */
  .account-type {
    margin-bottom: 15px;
  }

  .account-type label {
    margin-right: 10px;
    font-size: 16px;
  }

  /* Submit button styles */
  button[type="submit"] {
    width: 95%;
    padding: 10px;
    background-color: #26475f;
    color: #fff;
    border: none;
    border-radius: 3px;
    font-size: 16px;
    cursor: pointer;
  }

  button[type="submit"]:hover {
    background-color: #333;
  }
}

.btn-cancel-edit {
  font-size: medium;
  color: white;
  padding-block: 10px;

  width: 95%;
  margin: 8px auto;
  position: block;

  border: none;
  border-radius: 4px;
  cursor: pointer;
  background-color: hsl(354, 57%, 50%);
  margin-bottom: 15px;
}
.btn-cancel-edit:hover {
  background-color: #333;
}
