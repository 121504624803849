.pricing-container {
  margin-top: 20px;
  padding: 50px;
  display: flex;
  flex-direction: column;
  align-items: center;
  font-family: Arial, sans-serif;
  min-height: 75vh;
}

.banner-container {
  border: 2px solid #26475f;
  border-radius: 25px;
  padding: 10px;
  margin-bottom: 30px;
  width: 100%;
  max-width: 600px;
  background-color: #f8f9fa;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.banner {
  display: flex;
  justify-content: center;
  align-items: center;
}

.banner div {
  flex: 1;
  text-align: center;
  font-size: 20px;
  font-weight: bold;
  color: #26475f;
  padding: 10px 20px;
  cursor: pointer;
  transition: background-color 0.3s, color 0.3s;
  border-radius: 25px;
}

.banner div:first-child {
  border-radius: 25px 0 0 25px;
}

.banner div:last-child {
  border-radius: 0 25px 25px 0;
}

.banner div.active {
  color: #fff;
  background-color: #26475f;
}

.banner div:hover:not(.active) {
  background-color: lightgray;
}

.pricing-plans {
  display: flex;
  justify-content: space-around;
  width: 100%;
  max-width: 800px;
}

.pricing-plan {
  background-color: #fff;
  border: 1px solid #ddd;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  width: 45%;
  padding: 20px;
  text-align: left;
  margin: 0 10px;
  position: relative;
}

.pricing-plan h2 {
  font-size: 24px;
  margin-bottom: 10px;
  text-align: center;
}

.pricing-plan p.price {
  font-size: 32px;
  font-weight: bold;
  margin: 10px 0;
  text-align: center;
}

.pricing-plan p.price span {
  font-size: 20px;
}

.features-list {
  list-style: none;
  padding: 0;
}

.features-list li {
  font-size: 16px;
  margin: 5px 0;
  display: flex;
  align-items: center;
}

.features-list li::before {
  content: "✔";
  color: green;
  margin-right: 10px;
}

.section-header {
  font-weight: bold;
  margin-top: 10px;
}

.savings-banner {
  position: absolute;
  top: -20px;
  left: 50%;
  transform: translateX(-50%);
  background-color: #ffcc00;
  color: #000;
  padding: 5px 10px;
  border-radius: 5px;
  font-size: 14px;
  font-weight: bold;
  z-index: 1;
}

@media (max-width: 768px) {
  .pricing-container {
    padding: 20px;
  }

  .pricing-plans {
    flex-direction: column;
    gap: 8px;
    align-items: center;
  }

  .pricing-plan {
    width: 90%;
    margin-bottom: 20px;
  }
}

.signup_link_pricing {
  margin-top: 50px;
  margin-bottom: 60px;
  display: flex;
  min-width: 200px;
  width: 200px;
  /* max-width: 800px; */

  .btn-signup-pricing {
    padding: 14px 20px;
    margin: 8px 0;
    border: none;
    border-radius: 12px;
    cursor: pointer;
    width: 100%;
    color: white;
    background-color: #26475f;
    font-size: medium;
    font-weight: bold;
  }
  .btn-signup-pricing:hover {
    background-color: #333;
  }
}
