.dashboard {
  display: flex;
  min-height: 100vh;
}

.sidebar {
  width: 200px;
  background-color: #f0f0f0;
  padding: 20px;
}

.sidebar-option {
  /* margin-block: 8px; */
  /* border-radius: 5px; */
  padding: 10px;
  cursor: pointer;
  transition: background-color 0.3s;
  &:hover {
    background-color: #e0e0e0;
  }
}

.sidebar-option.active {
  background-color: #e0e0e0;
}

.main-content {
  flex: 1;
  padding: 20px;
}

.overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5); /* Semi-transparent black background */
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.overlay p {
  font-size: 18px;
  color: white;
  text-align: center;
  margin-bottom: 20px;
}
