/* Apply styles to the table */
.table-dashboard {
  border-collapse: separate;
  border-spacing: 10px; /* Adjust the spacing between columns and rows */
  width: 100%;
}

/* Style for table header */
.table-dashboard th {
  border-radius: 5px;
  background-color: lightgrey;
  padding: 10px;
  text-align: left;
}

/* Style for table body */
.table-dashboard td {
  background-color: #f9f9f9; /* Light gray background */
  padding: 10px;
  position: relative;
}

/* Style for table rows */
.pill-row {
  border-radius: 5px;
  transition: background-color 0.3s ease, box-shadow 0.3s ease, transform 0.3s ease,
    border 0.3s ease;
  transform: translateZ(0);
  will-change: transform;
}
.pill-row:hover {
  border-radius: 5px;
  cursor: pointer;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  transform: translateY(-2px);
  backface-visibility: hidden;
  will-change: transform;
}
.pill-row:hover td {
  border-radius: 5px;
  background-color: #eaeaea;
}

.table-container-dashboard {
  max-height: 400px; /* Set your desired max height */
  overflow-y: auto; /* Enable vertical scrolling */
  width: 100%;
}

.tabs {
  margin-top: 20px;
  display: flex;
  flex-direction: row;
  justify-content: center;

  width: 100%;
  border-radius: 5px;
  box-shadow: inset 0 0px 8px rgba(0, 0, 0, 0.1);
  /* border: 1px solid #ccc; */
  margin-bottom: 8px;
}

.tabs div {
  flex: 1;
  text-align: center;
  font-size: 16px;
  font-weight: bold;
  color: #26475f;
  padding: 10px 20px;
  cursor: pointer;
  transition: background-color 0.3s, color 0.3s;
  border-radius: 6px;
}

.tab_pending_action {
  position: relative;

}

.tabs div.active {
  color: #fff;
  background-color: #26475f;
}

.tabs div:hover:not(.active) {
  background-color: lightgrey;
}

.pending {
  background-color: #ffebeb; /* Light red background for pending rows */
}

.notification {
  display: inline-block;
  position: absolute;
  top: -7px;
  right: -8px;
  color: red;
  font-size: 14px;
  animation: pulsate 1.5s infinite;
  vertical-align: middle;
  z-index: 1;
}

.notification_header {
  display: inline-block;
  position: absolute;
  color: red;
  top: -1px;
  right: 4px;
  font-size: 16px;
  animation: pulsate 1.5s infinite;
  vertical-align: middle;
  z-index: 1;
}

@keyframes pulsate {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.2);
  }
  100% {
    transform: scale(1);
  }
}

.load-status-container {
  display: inline-flex;
  align-items: center;
  position: relative;
}

.load-status-text {
  margin-right: 5px;
}

.icon {
  margin-left: 5px;
  position: relative;
}

.icon-container {
  position: relative;
  display: inline-flex;
  align-items: center;
}

Submit button styles button[type="text"] {
  width: 10%;
  padding: 10px;
  background-color: #26475f;
  color: #fff;
  border: none;
  border-radius: 0px;
  font-size: 16px;
  cursor: pointer;
}

button[type="text"]:hover {
  background-color: #333;
}

.refresh-button {
  font-size: 20px;
  background-color: transparent;
  vertical-align: middle;
  border: none;
  cursor: pointer;
  transition: transform 0.3s ease; /* Add transition effect */
}

.refresh-button:hover {
  transform: scale(1.1); /* Scale up on hover */
}

.refresh-button:active {
  transform: scale(0.9); /* Scale down on click */
}

.refresh-button:focus {
  outline: none; /* Remove outline on focus */
}
