/* General Styling */
body,
html {
  margin: 0;
  padding: 0;
  font-family: 'Roboto', sans-serif;
  height: 100%;
  background-color: #f4f6f8;
}

.inbox-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px 20px 0 20px; /* Removed bottom padding */
  min-height: 100vh;
  box-sizing: border-box;
  width: 100%;
}

.activity-indicator {
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 20px;
  margin-top: 250px;
  color: #26475f;
}

/* Container for mobile view */
.container-mobile {
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 900px;
  align-items: center;
  background-color: #ffffff;
  border-radius: 10px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  overflow: hidden;
  padding-bottom: 10px; /* Reduced bottom padding */
  transition: box-shadow 0.3s ease;
}

.container-mobile:hover {
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.15);
}

.group-name-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding: 20px;
  background-color: #26475f;
  color: white;
  border-radius: 10px 10px 0 0;
  box-sizing: border-box;
}

.group-name {
  font-size: 24px;
  font-weight: 700; 
  margin-left: 20px; 
}

.go-back,
.info-button {
  background: none;
  border: none;
  cursor: pointer;
  color: white;
}

.list-mobile {
  width: 100%;
  padding: 0 20px;
  max-height: 78vh; /* Increased max-height */
  overflow-y: auto;
  box-sizing: border-box;
}

.item-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding: 15px;
  background-color: #fff;
  border: 1px solid #ccc;
  border-radius: 10px;
  margin: 10px 0;
  position: relative;
  cursor: pointer;
  transition: background-color 0.3s, box-shadow 0.3s, transform 0.3s, border-color 0.3s;
  box-sizing: border-box;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.05);
  transform: translateZ(0);
  will-change: transform;
}

.item-container:hover {
  background-color: #eaeaea;
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.1);
  border-color: #0288d1;
  transform: translateY(-2px);
  backface-visibility: hidden;
  will-change: transform;
}

.item {
  font-size: 18px;
  color: #26475f;
  font-weight: 700;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.latest-message {
  color: gray;
  font-size: 14px;
  font-style: italic; 
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.preview-message-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 80%;
  gap: 5px;
}

.notification-dot {
  width: 12px;
  height: 12px;
  background-color: red;
  border-radius: 50%;
  position: absolute;
  top: 15px;
  right: 15px;
  animation: pulsate 1.5s infinite;
}

@keyframes pulsate {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.1);
  }
  100% {
    transform: scale(1);
  }
}
