/* David Schraedel, July 2024 */

.user-account {
  display: flex;
  justify-content: center;
  /* align-items: flex-start; */
  width: 100%;
  margin-bottom: 120px;
  /* flex: 2; */
}

.user-account-files {
  /* flex: 1; */
  margin-inline: auto;

  width: 100%;
  margin-top: 20px;
  padding-block: 20px;
  margin-bottom: 25px;
  border-radius: 5px;
  box-shadow: inset 0px 0px 5px hsla(0, 0%, 20%, 0.25);

  h1 {
    text-align: center;
    margin-bottom: 20px;
  }
}

.user-account-form {
  width: 600px;
  max-width: 600px;
  margin: 0 auto;
  padding: 20px;
  /* border: 1px solid #ccc; */
  /* box-shadow: inset 0px 0px 20px hsla(0, 0%, 80%, 0.5); */
  border-radius: 10px;
  /* background-color: #f9f9f9; */
}

.user-account-form h1 {
  text-align: center;
  margin-bottom: 20px;
}

.radio-groups-container {
  /* display: flex;
  flex-wrap: wrap;
  justify-content: space-between; */
  display: grid;
  width: 100%;
  grid-template-columns: 50% 50%;
  row-gap: 5px;
}

.radio-group {
  margin-bottom: 10px;
  height: fit-content;
  /* width: 48%; */
  label {
    margin-right: 10px;
    /* padding-bottom: 5px; */
    display: block;
    margin-bottom: 5px;
    font-weight: bold;
  }
  div label {
    font-weight: normal;
    margin: 2px;
  }
  input {
    margin-right: 6px;
  }
}

.account-form-group {
  margin-bottom: 15px;
}

.account-form-group label {
  display: block;
  margin-bottom: 5px;
  font-weight: bold;
}

.account-form-group input {
  width: calc(100% - 22px);
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
}

.save-changes-button {
  padding: 10px 20px;
  border: none;
  background-color: #26475f;
  color: white;
  border-radius: 5px;
  cursor: pointer;
  display: block;
  font-size: 16px;
  margin-inline: auto;
  margin-top: 30px;
  margin-bottom: 5px;
}

.save-changes-button:hover {
  background-color: slategray;
}

.user-account img {
  width: 150px;
  min-width: 150px;
  height: 150px;
  border-radius: 50%;
  /* margin-bottom: 20px; */
  display: block;
  margin-inline: auto;
}

.avatar-form-group {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 40px;
  flex-direction: column;
  gap: 25px;

  input[type="file"] {
    margin-inline: auto;
    box-shadow: inset 0px 0px 5px hsla(0, 0%, 20%, 0.25);
    /* border: 1px solid #ccc; */
    border-radius: 5px;
    font-size: 16px;
    cursor: pointer;

    &::file-selector-button {
      border: none;
      padding: 10px 20px;
      background-color: #26475f;
      color: white;
      /* font-size: 16px; */
      border-radius: 5px;
      margin: 10px;
      margin-right: 15px;
      transition: background-color 0.25s ease-out;
    }

    /* fallback */

    &::-webkit-file-upload-button:hover {
      background-color: #333;
      cursor: pointer;
    }

    &::file-selector-button:hover {
      background-color: #333;
      cursor: pointer;
    }
  }
}

.back-button-container-account {
  position: relative;
  margin: 25px;
  margin-left: 35px;
}

.back-button {
  font-size: 28px;
  background-color: transparent;
  border: none;
  cursor: pointer;
}

.spinner {
  margin-left: 10px;
  border: 4px solid rgba(0, 0, 0, 0.1);
  border-top: 4px solid #000;
  border-radius: 50%;
  width: 16px;
  height: 16px;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

/* .delete-button {
  margin-top: 20px;
  padding: 10px 20px;
  font-size: 16px;
  cursor: pointer;
  background-color: red;
  color: white;
  border: none;
  border-radius: 4px;
} */
